<template>
  <div>
    <report-hub-table-view v-if="isTableView" />
    <report-hub-tree-view v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
    ReportHubTableView: () => import('@/components/core/ReportHubTableView'),
    ReportHubTreeView: () => import('@/components/core/ReportHubTreeView')
  },

  data: () => ({
    isTableView: null
  }),

  computed: {
    ...mapState(['policy'])
  },

  watch: {
    async $route(to, from) {
      if (from.name === 'Report-Hub' && from.name === to.name) {
        this.isTableView = this.policy.appUserPolicy[0].REPORT_HUB.every(e => e.crm_folder);
      }
    }
  },

  async mounted () {
    this.isTableView = this.policy.appUserPolicy[0].REPORT_HUB.every(e => e.crm_folder);
  }
};
</script>
